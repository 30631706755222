var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container section"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-two-fifths-desktop is-half-tablet"},[_c('card-component',{attrs:{"title":"Ingresa tu cuenta","icon":"account-circle","image":_vm.companyLogo}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-field',{attrs:{"label":"E-mail","type":{ 'is-danger': _vm.$v.email.$error },"message":[
              { 'Requerido. Ingresa tu email': !_vm.$v.email.required && _vm.$v.email.$error },
              {
                'Incorrecto. Ingresa un email válido': !_vm.$v.email.email && _vm.$v.email.$error
              }
            ]}},[_c('b-input',{attrs:{"type":"email","name":"email","placeholder":"Ingresa tu email","disabled":_vm.isLoading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('b-field',{attrs:{"label":"Contraseña","type":{ 'is-danger': _vm.$v.password.$error },"message":[
              {
                'Requerido. Ingresa tu contraseña':
                  !_vm.$v.password.required && _vm.$v.password.$error
              }
            ]}},[_c('b-input',{attrs:{"name":"password","type":"password","placeholder":"********","password-reveal":"","disabled":_vm.isLoading},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('hr'),_c('button',{staticClass:"button is-primary",class:{'is-loading':_vm.isLoading},attrs:{"type":"submit"}},[_vm._v(" Ingresar ")])],1)])],1)]),_c('div',{staticClass:"columns is-centered has-text-centered"},[_c('div',{staticClass:"column is-two-fifths-desktop is-half-tablet is-flex is-justify-content-center is-align-items-center"},[_c('small',{staticClass:"pr-1"},[_vm._v(" Powered by ")]),_c('a',{attrs:{"href":"https://weeare.pe","target":"_blank"}},[(_vm.isDarkModeActive)?_c('img',{attrs:{"src":require("@/assets/images/weeare-dark.png"),"alt":"Logo Weeare","width":"90"}}):_c('img',{attrs:{"src":require("@/assets/images/weeare.png"),"alt":"Logo Weeare","width":"90"}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'
class LoginService {
  login (data) {
    const response = axios.post('token-auth/', data)
    return response
  }
}

const instance = new LoginService()
export default instance

<template>
  <div class="container section">
    <div class="columns is-centered">
      <div class="column is-two-fifths-desktop is-half-tablet">
        <card-component
          title="Ingresa tu cuenta"
          icon="account-circle"
          :image="companyLogo"
        >
          <form @submit.prevent="login($event)">
            <b-field
              label="E-mail"
              :type="{ 'is-danger': $v.email.$error }"
              :message="[
                { 'Requerido. Ingresa tu email': !$v.email.required && $v.email.$error },
                {
                  'Incorrecto. Ingresa un email válido': !$v.email.email && $v.email.$error
                }
              ]"
            >
              <b-input
                v-model="email"
                type="email"
                name="email"
                placeholder="Ingresa tu email"
                :disabled="isLoading"
              />
            </b-field>
            <b-field
              label="Contraseña"
              :type="{ 'is-danger': $v.password.$error }"
              :message="[
                {
                  'Requerido. Ingresa tu contraseña':
                    !$v.password.required && $v.password.$error
                }
              ]"
            >
              <b-input
                v-model="password"
                name="password"
                type="password"
                placeholder="********"
                password-reveal
                :disabled="isLoading"
              />
            </b-field>
            <hr>
            <button type="submit" class="button is-primary" :class="{'is-loading':isLoading}">
              Ingresar
            </button>
          </form>
        </card-component>
      </div>
    </div>
    <div class="columns is-centered has-text-centered">
      <div class="column is-two-fifths-desktop is-half-tablet is-flex is-justify-content-center is-align-items-center">
        <small class="pr-1">
          Powered by
        </small>
        <a href="https://weeare.pe" target="_blank">
          <img
            v-if="isDarkModeActive"
            src="@/assets/images/weeare-dark.png"
            alt="Logo Weeare"
            width="90"
          />
          <img
            v-else
            src="@/assets/images/weeare.png"
            alt="Logo Weeare"
            width="90"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import CardComponent from '@/components/CardComponent'
import LoginService from '@/services/LoginService'
export default {
  name: 'Login',
  components: {
    CardComponent
  },
  data () {
    return {
      email: '',
      password: '',
      isLoading: false,
      companyLogo: this.$localStorage.get('company_logo') ? this.$localStorage.get('company_logo') : null
    }
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  computed: {
    ...mapState([
      'isDarkModeActive'
    ])
  },
  methods: {
    async login (e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          const response = await LoginService.login({
            email: this.email,
            password: this.password
          })
          this.$localStorage.set('email', response.data.user.email)
          this.$localStorage.set('token', response.data.token)
          this.$localStorage.set('company_name', response.data.user.company_name)
          this.$router.push({ name: 'select-local' })
          this.$buefy.snackbar.open({
            message: 'Bienvenido',
            queue: false
          })
        } catch (e) {
          this.isLoading = false
          const message = e.response ? e.response.data.non_field_errors[0] : 'Ocurrió un error'
          this.$buefy.toast.open({
            message,
            type: 'is-danger'
          })
        }
      }
    }
  }
}
</script>
